<template>
	<div class="table-wrapper">
		<table
			class="table"
			data-testid="table"
		>
			<thead>
			<tr class="tr-top">
				<th class="table-header">
					<ScCheckbox
						:modelValue="pageSelectionTracker.getAllSelected()"
						@update:modelValue="pageSelectionTracker.setAllSelected($event)"
						data-testid="table-checkbox"
					>
					</ScCheckbox>
				</th>
				<th
					class="table-header"
					style="width: 100%"
				>
					<div
						class="toolbar"
						style="display: flex; width: 100%"
						v-if="pageSelectionTracker.isAnySelected()"
					>
						<div
							class="toolbar-content"
							data-testid="toolbar-content"
						>
							<ScText
								size="14"
								class="toolbar-label"
								data-testid="selected-pages"
							>Selected: {{ pageSelectionTracker.getSelectedCountText(pages.length, isAllLoaded) }}
							</ScText>
							<ScButton
								view="flat"
								icon="upload"
								size="28"
								@click="publishSelected"
								:disabled="!isAnyChecked"
								data-testid="publish-selected-button"
							>
								Publish selected
							</ScButton>
						</div>
					</div>
					<a
						v-else
						@click="toggleSort(PageSortField.PagePath)"
						class="sort-link"
					>
						<span>Page path</span>
						<SortIcon :sort="getSort(PageSortField.PagePath)"/>
					</a>
				</th>
				<th class="table-header">
					<a
						@click="toggleSort(PageSortField.Language)"
						class="sort-link"
					>
						Language pair
						<SortIcon :sort="getSort(PageSortField.Language)"/>
					</a>
				</th>
				<th class="table-header">
					<a
						@click="toggleSort(PageSortField.AutoTranslatedPercent)"
						class="sort-link"
					>
						AI translation
						<SortIcon :sort="getSort(PageSortField.AutoTranslatedPercent)"/>
					</a>
				</th>
				<th class="table-header">
					<a
						@click="toggleSort(PageSortField.HumanTranslatedPercent)"
						class="sort-link"
					>
						Human reviewed
						<SortIcon :sort="getSort(PageSortField.HumanTranslatedPercent)"/>
					</a>
				</th>
				<th class="table-header">
					<a
						@click="toggleSort(PageSortField.WordCount)"
						class="sort-link"
					>
						Words
						<SortIcon :sort="getSort(PageSortField.WordCount)"/>
					</a>
				</th>
				<th class="table-header">
					<a
						@click="toggleSort(PageSortField.PublishDate)"
						class="sort-link"
					>
						Last published
						<SortIcon :sort="getSort(PageSortField.PublishDate)"/>
					</a>
				</th>
				<th class="table-header"> Actions</th>
			</tr>
			</thead>
			<tbody>
			<tr
				v-for="pageListItem in pages"
				:key="pageListItem.key"
				class="page"
				data-testid="page"
			>
				<td
					class="table-value"
					data-testid="checkbox"
				>
					<div class="table-value-container">
						<ScCheckbox
							:modelValue="pageSelectionTracker.getIsSelected(pageListItem.key)"
							@update:modelValue="pageSelectionTracker.setIsSelected(pageListItem.key, $event)"
						/>
					</div>
				</td>
				<td
					class="table-value table-value-path"
					style="width: 100%"
					data-testid="path"
				>
					<a
						:title="pageListItem.page.pagePath"
						:href="getBrowserLink(pageListItem.page.pageUrl, pageListItem.page.lang)"
						target="_blank"
					>
						/{{ pageListItem.page.pagePath }}
					</a>
				</td>
				<td
					class="table-value"
					data-testid="language-pair"
				>
					<div class="table-value-container table-language-pair">
						{{ webSite.sourceLanguage }}
						<ScIcon
							name="arrow-right"
							size="16"
							color="mulberry-purple-40"
						/>
						{{ pageListItem.page.lang }}
					</div>
				</td>
				<td
					class="table-value"
					data-testid="ai-translation"
				>
					<div class="table-value-container">
						<ScBadge
							size="small"
							:color="getAutoTranslatedCountColor(pageListItem.page)"
							semibold
						>
							{{ getAutoTranslatedCountText(pageListItem.page) }}
						</ScBadge>
					</div>
				</td>
				<td
					class="table-value"
					data-testid="human-reviewed"
				>
					<div class="table-value-container">
						<ScBadge
							size="small"
							:color="getScTranslatedCountColor(pageListItem.page)"
							semibold
						>
							{{ getScTranslatedCountText(pageListItem.page) }}
						</ScBadge>
					</div>
				</td>
				<td
					class="table-value"
					data-testid="words-count"
				>
					<div class="table-value-container">
						<WtPopover
							v-if="hasWordsCount(pageListItem) || hasError(pageListItem)"
							position="bottom right"
						>
							<template #activator>
								<ScIcon
									v-if="hasError(pageListItem)"
									name="alert"
									color="lightish-red"
									size="14"
								/>
								<ScTooltip v-if="hasWordsCount(pageListItem)">
									<template #activator>
											<span :class="getWordsCountClasses(pageListItem)">{{
													getWordsCountFormatted(pageListItem)
												}}</span>
									</template>
									<template>Approximate word count</template>
								</ScTooltip>
								<span
									v-else
									:class="getWordsCountClasses(pageListItem)"
								>Not counted</span
								>
							</template>
							<template>
								<div
									v-if="hasError(pageListItem)"
									class="table-value-count-error-details"
								>
									<ScText> The crawling was not completed due to page errors.</ScText>
								</div>
								<div
									v-else
									class="table-value-count-details"
								>
									<div>
										<ScText>Translated</ScText>
										<ScText>{{ getTranslatedWordsCountFormatted(pageListItem) }}</ScText>
									</div>
									<div>
										<ScText>Not translated</ScText>
										<ScText>{{ getNotTranslatedWordsCountFormatted(pageListItem) }}</ScText>
									</div>
								</div>
							</template>
						</WtPopover>
						<span
							v-else
							class="table-value-placeholder"
						>
								Not counted
							</span>
					</div>
				</td>
				<td
					class="table-value"
					data-testid="last-published-date"
				>
					<div class="table-value-container">
							<span v-if="isPublished(pageListItem)">
								{{ getLastPublishedDate(pageListItem.page) }}
							</span>
						<span
							v-else
							class="table-value-placeholder"
						>
								Not published
							</span>
					</div>
				</td>
				<td class="table-value">
					<div class="table-value-container table-row-actions">
						<template v-if="pageListItem.isPublishing || pageListItem.isEditing">
							<ScLoaderSpin size="16"/>
						</template>
						<template v-else>
							<ScTooltip>
								<template #activator>
									<ScButton
										icon="post-editing"
										size="28"
										view="flat"
										:disabled="!getCanEdit(pageListItem)"
										@click="editRow(pageListItem)"
										data-testid="edit-button"
									/>
								</template>
								<ScText v-if="getCanEdit(pageListItem)">Edit in Smartcat</ScText>
								<ScText v-else>No content to edit</ScText>
							</ScTooltip>
							<ScButton
								icon="upload"
								size="28"
								view="flat"
								@click="publishRow(pageListItem)"
								data-testid="publish-button"
							/>
						</template>
					</div>
				</td>
			</tr>
			</tbody>
		</table>
		<div class="table-loader">
			<ScLoaderSpin
				v-if="loadingPages"
				size="32"
			/>
		</div>
		<div
			v-if="!loadingPages && pages.length === 500"
			class="page-limit-message"
		>
			<ScText>
				List of pages is limited by {{ pages.length }} items. Use search or filters to see other pages.
			</ScText>
		</div>
	</div>
</template>

<script lang="ts">
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import {
		ScBadge,
		ScButton,
		ScCheckbox,
		ScIcon,
		ScLoaderSpin,
		ScText,
		ScTooltip,
	} from '@smartcat/design-system-vue2';
	import SortIcon from '@/components/web-site-pages/sort-icon.vue';

	import { CurrentSettingsDto, PageSortField, PageTranslationInfo, SortDirection, WebSiteInfo } from '@/shared';
	import { CustomizationService } from '@/script/logic/customization/customizations';
	import WtPopover from '@/components/widgets/wt-popover/wt-popover.vue';
	import { PageListItem } from "@/components/web-site-pages/page-list/page-list-item";
	import { PageSelectionTracker } from "@/components/web-site-pages/page-list/page-selection-tracker";

	@Component({
		components: { ScText, WtPopover, ScTooltip, ScBadge, ScButton, SortIcon, ScCheckbox, ScIcon, ScLoaderSpin },
	})
	export default class WTPagesTable extends Vue {


		@Prop({ required: true })
		public webSite: WebSiteInfo;

		@Prop({ required: true })
		public settings: CurrentSettingsDto;

		@Prop({ required: true })
		public customizationService: CustomizationService;

		@Prop({ default: [] })
		public pages: PageListItem[];

		@Prop({ default: '' })
		public searchQuery: string;

		@Prop({ default: false })
		public loadingPages: boolean;

		@Prop({ default: PageSortField.PagePath })
		public sortField: PageSortField;

		@Prop({ default: SortDirection.Asc })
		public sortDirection: SortDirection;

		@Prop()
		public isAnyChecked: boolean;

		@Prop({ default: false })
		public isAllLoaded: boolean;

		@Prop()
		public pageSelectionTracker: PageSelectionTracker;

		public PageSortField = PageSortField;

		private numFormatter = Intl.NumberFormat();

		public hasWordsCount(row: PageListItem): boolean {
			return !!row.page.wordCount;
		}

		public hasError(row: PageListItem) {
			return row.page.lastCrawlError;
		}

		public getWordsCountClasses(row: PageListItem) {
			return {
				'table-value-count': true,
				'table-value-count-error': this.hasError(row),
			};
		}

		public getTranslatedWordsCountFormatted(row: PageListItem) {
			return this.numFormatter.format(
				(row.page.autoTranslatedPercent + row.page.humanTranslatePercent) * row.page.wordCount);
		}

		public getNotTranslatedWordsCountFormatted(row: PageListItem) {
			return this.numFormatter.format(
				(100 - row.page.autoTranslatedPercent - row.page.humanTranslatePercent) * row.page.wordCount);
		}

		public getWordsCount(row: PageListItem) {
			return row.page.wordCount;
		}

		public getWordsCountFormatted(row: PageListItem) {
			return this.numFormatter.format(this.getWordsCount(row));
		}

		public getCanEdit(row: PageListItem) {
			return this.hasWordsCount(row) && this.getWordsCount(row) > 0;
		}

		public getLastPublishedDate(page: PageTranslationInfo) {
			const date = page.lastPublishDate;
			return date ? this.formatDate(date) : '';
		}

		public editRow(row: PageListItem) {
			this.$emit('edit', row);
		}

		public publishRow(pageListItem: PageListItem) {
			this.$emit('publish-item', pageListItem);
		}


		public publishSelected() {
			this.$emit('publish-selected');
		}

		public toggleSort(col: PageSortField) {
			this.$emit('sort', col);
		}

		public isPublished(row: PageListItem) {
			return !!row.page.lastPublishDate;
		}

		public getSort(field: PageSortField) {
			if (this.sortField === field) {
				return this.sortDirection == SortDirection.Asc ? 'asc' : 'desc'
			}
			return null;
		}

		public getBrowserLink(pageUrl: string, locale: string) {
			if (!this.webSite || !pageUrl) {
				return null;
			}
			pageUrl = pageUrl.replace(/#.*/, '');
			return `/browser/${this.webSite.id}?pageUrl=${encodeURIComponent(pageUrl)}&scProxyLang=${locale}`;
		}

		public getFullUrl(pagePath: string, locale: string = null) {
			const defaultUrl = 'https://' + this.webSite.host + '/' + (pagePath == '__index' ? '' : pagePath);
			const customization = this.customizationService.getCustomization();
			if (customization && customization.canChangeLocale()) {
				return customization.getUrlForLocale(defaultUrl, locale) || defaultUrl;
			}
			return defaultUrl;
		}

		public getScTranslatedCountColor(page: PageTranslationInfo) {
			const count = page.humanTranslatePercent;
			if (count == null || isNaN(count)) {
				return 'grey';
			}
			if (count === 0) {
				return 'grey';
			}
			if (count == 100) {
				return 'green';
			}
			return 'purple';
		}

		public getAutoTranslatedCountColor(page: PageTranslationInfo) {
			const count = page.autoTranslatedPercent;
			if (count == null || isNaN(count)) {
				return 'grey';
			}
			if (count === 0) {
				return 'grey';
			}
			if (count == 100) {
				return 'green';
			}
			return 'purple';
		}

		public getAutoTranslatedCountText(page: PageTranslationInfo) {
			const count = page.autoTranslatedPercent;
			if (!isFinite(count)) {
				return '?';
			}
			if (count == null || isNaN(count)) {
				return '0%';
			}
			return count + '%';
		}

		public getScTranslatedCountText(page: PageTranslationInfo) {
			const count = page.humanTranslatePercent;
			if (!isFinite(count)) {
				return '?';
			}
			if (count == null || isNaN(count)) {
				return '0%';
			}
			return count + '%';
		}

		private formatDate(d: Date | string) {
			const v = typeof d === 'string' ? new Date(d) : d;
			const twoDigits = function (n: number) {
				return (n < 10 ? '0' : '') + n;
			};
			return (
				v.getFullYear() +
				'-' +
				twoDigits(v.getMonth() + 1) +
				'-' +
				twoDigits(v.getDate()) +
				' ' +
				twoDigits(v.getHours()) +
				':' +
				twoDigits(v.getMinutes())
			);
		}
	}
</script>

<style scoped lang="less">
@import '@smartcat/design-system-vue2/colors';

* {
	font-family: Inter, sans-serif;
}

.table-loader {
	display: flex;
	justify-content: center;
	margin-top: 10px;
	margin-left: 29px;
}

.table-wrapper {
	padding: 0 20px;
}

.toolbar-content {
	display: flex;
	align-items: center;
	gap: 8px;
	text-transform: none;
}

.table {
	border-spacing: 0;
	margin-top: 10px;

	.tr-top {
		position: sticky;
		top: 0;
		background: white;
		z-index: 11;
	}

	tr td {
		border-top: 1px solid #f2f1f4;
	}

	tr td,
	tr th {
		&:last-child {
			border-left: 1px solid #f2f1f4;
		}
	}

	a {
		color: @mulberry-purple-80;
		text-decoration: underline;
	}

	.table-header {
		color: @mulberry-purple-40;
		user-select: none;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 12px;
		text-align: left;
		padding: 14px 12px;
		line-height: 20px;
		font-weight: 600;
		text-transform: uppercase;
		height: 53px;
		vertical-align: middle;

		.sort-link {
			color: @mulberry-purple-40;
			text-decoration: none;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			cursor: pointer;
		}
	}

	.table-value {
		max-width: 300px;
		color: @mulberry-purple-80;
		white-space: nowrap;
		text-overflow: ellipsis;
		font-size: 13px;
		text-align: left;
		font-weight: 400;
		padding: 14px 12px;
		line-height: 20px;

		.table-value-container {
			display: flex;
			align-items: center;
		}
	}

	.table-value-placeholder {
		color: @mulberry-purple-40;
	}
}

.table-language-pair {
	gap: 5px;
}

.table-value-path {
	overflow: hidden;
}

.table-value-count {
	text-decoration: underline;
	text-decoration-style: dashed;
	text-underline-offset: 3px;

	&.table-value-count-error {
		margin-left: 5px;
	}
}

.table-value-count-error-details {
	max-width: 250px;
	white-space: initial;
}

.table-value-count-details {
	display: flex;
	flex-direction: column;
	gap: 5px;

	& > div {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.page-limit-message {
	display: flex;
	justify-content: center;
	margin: 20px 0;
}
</style>
