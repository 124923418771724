export function tryExtractSectionId(contentUrl: string) {
	const prefix = '__sections/';
	if (contentUrl.indexOf(prefix) > 0) {
		return contentUrl.substring(contentUrl.indexOf(prefix) + prefix.length);
	}
	return null;
}

export function getPathSimple(contentUrl: string) {
	const sectionId: string = tryExtractSectionId(contentUrl);
	if (sectionId) {
		return '__sections/' + sectionId;
	}

	const path = contentUrl
		.replace(/https?:\/\//i, '')
		.replace(/^.*?(\/|$)/, '')
		.replace(/\?.*/, '')
		.replace(/#.*/, '')
		.replace(/\/$/, '')
		.replace(/^\/+/, '')
		.toLowerCase();
	return decodeURIComponent(path).replace(/\+/g, ' ') || '__index';
}
