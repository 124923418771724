<template>
	<SignInBlocker
		v-if="authorizationRequired"
		:web-site="webSite"
	/>
	<div
		v-else-if="!webSite || !settings"
		style="padding: 50px"
	>
		<ScLoaderSpin/>
	</div>
	<div
		v-else
		class="web-pages"
		style="width: 100%;"
		data-testid="web-pages"
	>
		<Notifications/>
		<ConfirmationsContainer/>
		<ScriptPopup
			v-if="showPublishPopup"
			:validation-result="scriptValidationResult"
			:script="publishedScript"
			:validate-in-progress="scriptValidationInProgress"
			@cancel="showPublishPopup = false"
			@click-validate="onPublishValidationCallback(false)"
			@click-force-publish="onPublishValidationCallback(true)"
		/>
		<div class="navigation" data-testid="navigation">
			<span>
				<a :href="getProjectsLink()" data-testid="projects-link"> Projects </a>
			</span>
			<span class="arrow">/</span>
			<span>
				<a :href="getProjectLink()" data-testid="project-link">
					{{ project && project.name }}
				</a>
			</span>
			<span class="arrow">/</span>
			<span> All Pages </span>
			<div style="position: absolute; right: 20px">
				<SmartwordIndicator
					v-if="smartwordInfo"
					:max-smartwords="smartwordInfo.monthlyCount"
					:current-smart-words-usage-count="smartwordInfo.currentCount"
					:subscription-name="''"
					@click-top-up-balance="topUpBalance"
					@click-go-subscribtion="goToSubscription"
					@click-go-faq="goToFaq"
				/>
			</div>
		</div>
		<div v-if="webSite" class="tablebar" data-testid="tablebar">
			<div class="tablebar-col">
				<div class="header" data-testid="header">
					{{ webSite.host }}
				</div>
				<div class="tablebar-details">
					<div v-if="webSiteStats" class="tablebar-stats" data-testid="tablebar-stats">
						{{ webSiteStats.pageCount }} pages / {{ webSiteStats.wordCount }} words
					</div>
					<!--					<div style="position: absolute">-->
					<div v-if="isPublishInProgress()" class="tablebar-publish-stats"
						 data-testid="tablebar-publish-stats">
						<ScLoaderSpin/>&nbsp; Publish progress: {{ getPublishProgress() }}
					</div>

					<!--					</div>-->
					<div class="tablebar-crawl-widget">
						<CrawlerWidget
							v-if="showCrawlerWidget"
							:web-site="webSite"
							@progress="onCrawlerProgress"
							@crawl-started="onCrawlerStarted"/>
					</div>
				</div>
			</div>
			<div class="tablebar-col tablebar-col-right">
				<a
					:href="getCsvExportUrl()"
					target="_blank"
				>
					<ScButton
						view="flat"
						icon="upload"
						size="28"
						data-testid="csv-button"
					>
						Export to csv
					</ScButton>
				</a>
				<ScInput
					style="width: 350px"
					icon="search"
					placeholder="Search by page path"
					:modelValue="getQuery()"
					@input="setQueryWithDebounce($event.target.value)"
					data-testid="search-input"
				/>
			</div>
		</div>
		<div
			class="body"
			style="width: 100%"
		>
			<div v-if="pageListLoader.isLoaded() && !pageListLoader?.pages?.length">
				<div style="padding: 20px; text-align: center; color: #6b6679">
					No pages found
				</div>
			</div>
			<InfiniteScroll
				v-else
				@load-more="loadNextBatch"
				:loaded="pageListLoader.isLoaded()"
				:loading="pageListLoader.isLoading()">

				<WtPagesTable
					v-if="webSite && settings"
					:web-site="webSite"
					:settings="settings"
					:pages="pageListLoader.pages"
					:search-query="searchQuery"
					:customization-service="customizationService"
					:loading-pages="loadingPages"
					:pageSelectionTracker="pageSelectionTracker"
					:sort-direction="pageListLoader.getSortDirection()"
					:sort-field="pageListLoader.getSortField()"
					:is-any-checked="pageSelectionTracker.isAnySelected()"
					:is-all-loaded="pageListLoader.isLoaded()"
					@edit="edit"
					@sort="applySort"
					@publish-selected="publishSelected"
					@publish-item="publishItem"
					@update-stats="updateStatsForSelected"
				/>
				<div style="padding-left: 100px">
					<ScLoaderSpin size="32" v-if="pageListLoader.isLoading()">
					</ScLoaderSpin>
				</div>
			</InfiniteScroll>

		</div>
	</div>
</template>

<script lang="ts">

	import { Component, Vue } from 'vue-property-decorator';
	import { SmartcatApiService } from '@/shared/api/smartcatApiService';
	import { ApiClient } from '@/shared/api/api-client';
	import {
		CurrentSettingsDto,
		PageSortField,
		PageTranslationSelectionMode,
		PageTranslationStatisticsCalculateRequest,
		ScriptPublicationValidationResult,
		SmartcatProjectExportModel,
		SmartWordsInfo,
		SortDirection,
		WebSiteCrawlInfoResponse,
		WebSiteInfo,
		WebSitePublishInfo,
		WebSiteStatsInfo,
	} from '@/shared';
	import {
		ScBadge,
		ScButton,
		ScCheckbox,
		ScIcon,
		ScInput,
		ScLoaderSpin,
		ScTooltip
	} from '@smartcat/design-system-vue2';
	import { inject } from '@/components/common/di';
	import { NotificationService } from '@/components/widgets/notifications/notificationService';
	import ScriptPopup from '@/components/widgets/script-popup/script-popup.vue';
	import { defaultLanguages } from '@/shared/resources/languages';
	import Notifications from '@/components/widgets/notifications/notifications.vue';
	import { CustomizationService } from '@/script/logic/customization/customizations';
	import SortIcon from '@/components/web-site-pages/sort-icon.vue';
	import WtButton from '@/components/widgets/wt-button/wt-button.vue';
	import ConfirmationsContainer from '@/components/widgets/confirm-popup/confirmations-container.vue';
	import SignInBlocker from '@/components/web-site-pages/sign-in-blocker.vue';
	import WtPagesTable from '@/components/web-site-pages/wt-pages-table.vue';
	import CrawlerWidget from '@/components/web-site-pages/crawler-widget.vue';
	import SmartwordIndicator from '@/components/widgets/smartword-indicator/smartword-indicator.vue';
	import InfiniteScroll from "@/components/web-site-pages/page-list/infinite-scroll.vue";
	import { PageListItem } from "@/components/web-site-pages/page-list/page-list-item";
	import { PageListLoader } from "@/components/web-site-pages/page-list/page-list-loader";
	import { PageSelectionTracker } from "@/components/web-site-pages/page-list/page-selection-tracker";
	import { delay } from "@/shared/utils/delay";
	import { debounce } from "@/shared/utils/debounce";


	@Component({
		components: {
			InfiniteScroll,
			SmartwordIndicator,
			CrawlerWidget,
			WtPagesTable,
			SignInBlocker,
			ConfirmationsContainer,
			WtButton,
			ScTooltip,
			SortIcon,
			Notifications,
			ScriptPopup,
			ScButton,
			ScLoaderSpin,
			ScBadge,
			ScCheckbox,
			ScInput,
			ScIcon,
		},
	})
	export default class WebSitePageList extends Vue {
		public scriptValidationInProgress = false;
		public authorizationRequired = false;
		public scriptValidationFailed = false;
		public scriptValidationResult?: ScriptPublicationValidationResult = null;
		public showPublishPopup = false;
		public webSiteStats: WebSiteStatsInfo = null;
		public publishInfo: WebSitePublishInfo = null;
		public onPublishValidationCallback: (skipValidation: boolean) => void = null;
		public publishedScript: string = null;
		public currentPagePath: string = null;
		public searchQuery = '';
		public project: SmartcatProjectExportModel = null;
		public loadingPages = false;
		public pageSelectionTracker = new PageSelectionTracker();
		public smartwordInfo: SmartWordsInfo = null;

		private apiService = new SmartcatApiService(new ApiClient());
		private webSiteId: string = null;
		public webSite: WebSiteInfo = null;
		public settings: CurrentSettingsDto = null;
		public customizationService: CustomizationService = null;
		public numFormatter = Intl.NumberFormat();
		public lastPagesUpdateTime = new Date();
		public pageListLoader: PageListLoader = null;
		private disposed: boolean;
		private publishedPagesPath = new Set<string>();

		public get isLoading(): boolean {
			return this.pageListLoader.isLoading();
		}

		public get isLoaded(): boolean {
			return this.pageListLoader.isLoaded();
		}

		public get showCrawlerWidget(): boolean {
			if (!this.webSite || !this.customizationService) {
				return false;
			}

			const customization = this.customizationService.getCustomization()

			if (customization?.shouldHideCrawlerButton()) {
				return false;
			}

			return true;
		}

		async mounted() {
			this.webSiteId = this.$route.params.webSiteId as string;
			this.pageListLoader = new PageListLoader(this.webSiteId);
			if (this.$route.query?.q) {
				this.pageListLoader.setQuery(this.$route.query.q as string, true);
			}
			if (this.$route.query?.sortField) {
				this.pageListLoader.setSortField(
					this.$route.query.sortField as PageSortField,
					this.$route.query.sortDirection as SortDirection);
			}

			this.webSite = await this.apiService.getWebSite(this.webSiteId);
			this.settings = await this.apiService.getSettings();
			this.customizationService = new CustomizationService({
				rootUrl: this.webSite.rootUrl,
				langs: [this.webSite.sourceLanguage, ...this.webSite.targetLanguages],
			});
			this.project = await this.apiService.getWebSiteProject(this.webSiteId);
			const currentUserSettings = await this.apiService.getSettings();
			if (currentUserSettings.currentAccountId !== this.webSite.accountId) {
				this.authorizationRequired = true;
				return;
			}
			this.smartwordInfo = await this.apiService.getSmartwordsInfo(this.webSiteId);
			this.webSiteStats = await this.apiService.getWebSiteStats(this.webSiteId);
			this.publishInfo = await this.apiService.getWebSitePublishProgress(this.webSiteId);
			this.monitorPublishProgress().then();
		}

		beforeDestroy() {
			this.disposed = true;
		}

		onCrawlerStarted() {
			this.pageListLoader.setLoadLatestEnabled(true);
		}

		onCrawlerProgress(p: WebSiteCrawlInfoResponse) {
			if (p.crawlInProgress) {
				if (!this.pageListLoader.isLoading() &&
					this.pageListLoader.getLoadLatestEnabled() &&
					this.pageListLoader.pages.length < 100) {
					this.pageListLoader.loadNextBatch();
				}
			}
		}

		public getCsvExportUrl() {
			return '/api/web-site/' + this.webSiteId + '/pages-csv';
		}

		public getQuery() {
			return this.$route.query.q as string;
		}

		public setQueryWithDebounce = debounce((v: string) => this.setQuery(v), 800);

		public setQuery(v: string) {
			this.pageListLoader.setQuery(v);
			this.$router.push({
				query: {
					sortField: this.pageListLoader.getSortField(),
					sortDirection: this.pageListLoader.getSortDirection(),
					q: this.pageListLoader.getQuery()
				}
			});
		}

		public async updateStatsForSelected(listItems: PageListItem[]) {
			for (const row of listItems) {
				const page = row.page;
				const locale = row.page.lang;

				row.isUpdating = true;
				const req: PageTranslationStatisticsCalculateRequest = {
					webSiteId: this.webSite.id,
					pagePaths: [page.pagePath],
					locales: [locale],
				};
				const updatedPages = await this.apiService.calculateStats(req);
				this.pageListLoader.updatePages(updatedPages);
				row.isUpdating = false;
			}
		}

		public async publishItem(item: PageListItem, skipValidation = false) {
			this.scriptValidationInProgress = true;
			this.scriptValidationFailed = false;
			item.isPublishing = true;
			try {
				await this.apiService.publishTranslator(this.webSite.id);
				if (!skipValidation) {
					const validationResult = await this.apiService.validatePublication(this.webSite.id, null);
					if (validationResult !== ScriptPublicationValidationResult.ScriptIsOk) {
						this.scriptValidationResult = validationResult;

						if (this.showPublishPopup) {
							this.scriptValidationFailed = true;
						}
						this.publishedScript = await this.apiService.getWebSiteScript(this.webSite.id);
						this.showPublishPopup = true;
						this.onPublishValidationCallback = (skipValidation) => this.publishItem(item, skipValidation);
						return;
					}
				}
				await this.apiService.publishSelection(this.webSite.id, {
					selectionMode: PageTranslationSelectionMode.Explicit,
					explicitSelection:
						{
							selectedItems: [
								{
									path: item.page.pagePath,
									lang: item.page.lang,
								},
							],
						}
				});
				this.publishedPagesPath.add(item.page.pagePath);
				this.showPublishPopup = false;
				await this.updatePublishProgress();
			} finally {
				this.scriptValidationInProgress = false;
				item.isPublishing = false;
			}
		}

		public async publishSelected(skipValidation = false) {
			this.scriptValidationInProgress = true;
			this.scriptValidationFailed = false;

			try {
				await this.apiService.publishTranslator(this.webSite.id);
				if (!skipValidation) {
					const validationResult = await this.apiService.validatePublication(this.webSite.id, null);
					if (validationResult !== ScriptPublicationValidationResult.ScriptIsOk) {
						this.scriptValidationResult = validationResult;

						if (this.showPublishPopup) {
							this.scriptValidationFailed = true;
						}

						this.publishedScript = await this.apiService.getWebSiteScript(this.webSite.id);
						this.showPublishPopup = true;
						this.onPublishValidationCallback = (skipValidation) => this.publishSelected(skipValidation);
						return;
					}
				}

				const query = this.pageListLoader.getQuery();
				const selection = this.pageSelectionTracker.getCurrentSelection(query);
				await this.apiService.publishSelection(this.webSite.id, selection);
				const pagePathList = this.pageListLoader.pages
					.filter(p => this.pageSelectionTracker.getIsSelected(p.key))
					.map(x => x.page.pagePath);
				pagePathList.forEach(p => this.publishedPagesPath.add(p));

				this.showPublishPopup = false;
				await this.updatePublishProgress();
			} finally {
				this.scriptValidationInProgress = false;
			}
		}

		public getProjectsLink() {
			return this.settings.smartcatApiUrl.replace(/\/$/, '') + '/projects/';
		}

		public getProjectLink() {
			return this.settings.smartcatApiUrl.replace(/\/$/, '') + '/projects/' + this.webSite.scProjectId;
		}


		public async topUpBalance() {
			const s = await this.apiService.getSettings();
			window.location.href = s.smartcatApiUrl.replace(/\/$/, '') + '/billing/payment-wizard?external';
		}


		public async goToSubscription() {
			const s = await this.apiService.getSettings();
			window.location.href = s.smartcatApiUrl.replace(/\/$/, '') + '/billing/payment-wizard?external';
		}

		public goToFaq() {
			window.open('https://www.smartcat.com/smartwords', '_blank');
		}

		public async loadNextBatch() {
			this.pageListLoader.loadNextBatch();
		}

		public getUrlForLocale(pageUrl: string, locale: string = null) {
			const customization = this.customizationService.getCustomization();
			if (customization && customization.canChangeLocale()) {
				return customization.getUrlForLocale(pageUrl, locale) || pageUrl;
			}
			return pageUrl;
		}

		public async applySort(newSortField: PageSortField) {
			const newSortDirection = this.pageListLoader.getSortField() == newSortField
				? this.invertSortDirection(this.pageListLoader.getSortDirection())
				: this.pageListLoader.getSortDirection();
			this.$router.push({
				query: {
					sortField: newSortField,
					sortDirection: newSortDirection,
					q: this.pageListLoader.getQuery()
				}
			});
			this.pageListLoader.setSortField(newSortField, newSortDirection);
		}

		public async edit(row: PageListItem) {
			const locale = row.page.lang;
			const canonicalUrl = this.getUrlForLocale(row.page.pageUrl, locale);
			try {
				row.isEditing = true;
				const syncResults = await this.apiService.syncPageWithSmartcat({
					force: true,
					webSiteId: this.webSite.id,
					contentUrl: canonicalUrl,
					fragments: [],
					targetLang: [locale],
				});
				const pageSyncResult =
					syncResults.length === 1
						? syncResults[0]
						: syncResults.find((x) => !x.items?.[0]?.pagePath.includes('__section'));

				if (!pageSyncResult?.items?.[0]?.smartcatDocumentId) {
					console.log('No document to edit', pageSyncResult);
					return;
				}

				const docId = pageSyncResult.items[0].smartcatDocumentId.replace(/_\d*$/, '');
				const url = await this.getDocumentEditUrl(docId, locale);
				window.open(url, '_blank');
			} catch (e) {
				console.error(e);
			} finally {
				row.isEditing = false;
			}
		}

		public async publish(pageListItem: PageListItem, skipValidation: boolean) {
			const pagePath = pageListItem.page.pagePath;
			this.currentPagePath = pagePath;
			this.scriptValidationInProgress = true;
			this.scriptValidationFailed = false;

			try {
				await this.apiService.publishTranslator(this.webSite.id);
				if (!skipValidation) {
					const validationResult = await this.apiService.validatePublication(this.webSite.id, null);
					if (validationResult !== ScriptPublicationValidationResult.ScriptIsOk) {
						this.scriptValidationResult = validationResult;

						if (this.showPublishPopup) {
							this.scriptValidationFailed = true;
						}

						this.publishedScript = await this.apiService.getWebSiteScript(this.webSite.id);
						this.onPublishValidationCallback = (skipValidation) => {
							this.publish(pageListItem, skipValidation);
						};
						this.showPublishPopup = true;
						return;
					}
				}

				pageListItem.isPublishing = true;
				await this.apiService.publishByPath({
					pagePath,
					webSiteId: this.webSite.id,
					locales: this.webSite.targetLanguages,
				});
				this.markPublished(pageListItem);
				this.showPublishPopup = false;
				inject(NotificationService).showSuccess('Page published');
			} finally {
				pageListItem.isPublishing = false;
				this.scriptValidationInProgress = false;
			}
		}

		public markPublished(page: PageListItem) {
			page.page.lastPublishDate = new Date();
		}

		public isPublishInProgress() {
			if (!this.publishInfo?.tasks) {
				return false;
			}
			return this.publishInfo.tasks.find((x) => x.isUnfinished);
		}

		public getPublishProgress() {
			if (!this.publishInfo) {
				return '100%';
			}
			const currentTask = this.publishInfo.tasks.find(x => x.isUnfinished);
			if (currentTask) {
				const totalComplete = currentTask.completeCount;
				const totalFound = currentTask.pathCount;
				if (totalFound === 0) {
					return 'Pending...';
				}
				let percent = Math.round((totalComplete / totalFound) * 100);
				if (percent > 100) {
					percent = 100;
				}
				return percent + '%';
			}
			return 'Pending...';
		}

		private async getDocumentEditUrl(docId: string, locale: string) {
			const s = await this.apiService.getSettings();
			const apiUrl = s.smartcatApiUrl.replace(/\/$/, '');
			const lang = defaultLanguages().find((x) => x.cultureName === locale);
			const backUrl = encodeURIComponent(window.location.href);
			const url = `${apiUrl}/editor?v=2&documentId=${docId}&languageId=${lang.id}&backUrl=${backUrl}`;
			return url;
		}


		private invertSortDirection(s: SortDirection) {
			if (s == SortDirection.Asc) {
				return SortDirection.Desc;
			}
			return SortDirection.Asc;
		}

		private async updatePublishProgress() {
			this.publishInfo = await this.apiService.getWebSitePublishProgress(this.webSiteId);
		}

		private async monitorPublishProgress() {
			while (!this.disposed) {
				if (this.isPublishInProgress()) {
					await this.updatePublishProgress();
					if (!this.isPublishInProgress()) {
						inject(NotificationService).showSuccess('Publishing completed');
						await this.pageListLoader.update([...this.publishedPagesPath]);
						this.publishedPagesPath.clear();
					}
				}
				await delay(1000);
			}
		}
	}
</script>

<style lang="less" scoped>
@import '@smartcat/design-system-vue2/colors';

* {
	font-family: Inter, sans-serif;
}

.navigation {
	padding-top: 16px;
	padding-bottom: 16px;
	padding-left: 30px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid @mulberry-purple-20;
	color: #6b6679;

	a {
		font-size: 12px;
		color: #6b6679;
		color: #6b6679;
		line-height: 20px;
	}

	.arrow {
		margin: 10px;
	}

	.head {
		padding-left: 5px;
		font-size: 14px;
		color: @mulberry-purple-50;
		line-height: 16px;
		font-weight: bold;
	}
}

.toolbar {
	display: flex;
	justify-content: space-between;
	position: absolute;
	z-index: 1000;
	left: 60px;
}

.toolbar-content {
	background: white;
	left: 44px;
	padding-top: 2px;

	.toolbar-label {
		padding: 12px;
		font-size: 12px;
		color: @mulberry-purple-90;
		font-weight: bold;
		text-transform: uppercase;
	}
}

.sc-checkbox.sc-checkbox_checked,
.sc-checkbox__state {
	//background: @mulberry-purple !important;
	border-color: @mulberry-purple !important;
	color: #00a182 !important;
}

.tablebar {
	display: flex;
	justify-content: space-between;
	margin-top: 30px;
	padding: 0 20px;
	width: 100%;

	.header {
		font-weight: bold;
		font-size: 18px;
		color: @mulberry-purple;
	}
}

.tablebar-col {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 20px;
}

.tablebar-col-right {
	align-items: flex-end;
}

.tablebar-details {
	display: flex;
	align-items: center;
}

.tablebar-publish-stats {
	display: flex;
	align-items: center;
	position: fixed;
	bottom: 10px;
	left: 10px;
	//width: 250px;
	background: white;
	padding: 10px 20px;
	border-radius: 3px;
	z-index: 100;
	border: 1px solid @mulberry-purple-10;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}

.tablebar-crawl-widget {
	flex: 1 0 auto;
	padding-left: 10px;
	margin-left: 20px;
	border-left: 1px solid @mulberry-purple-10;
}
</style>
