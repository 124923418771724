import { CustomizationService } from '@/script/logic/customization/customizations';
import { getPathSimple, tryExtractSectionId } from '@/script/logic/translation/get-path-simple';

export class UrlFormattingService {
	customizationService: CustomizationService;

	constructor(customizationService: CustomizationService) {
		this.customizationService = customizationService;
	}

	public getContentUrl(pageUrl: string, sectionId?: string): string {
		const pageUrlWithoutHash = pageUrl.replace(/\#.*/gi, '');
		return pageUrlWithoutHash + '#/__sections/' + sectionId;
	}

	public formatUrl(format: string, webSiteId: string, pagePath: string, pageUrl: string, locale?: string): string {
		return format
			.replace('{pagePath}', pagePath)
			.replace('{pageUrl}', encodeURIComponent(pageUrl))
			.replace('{contentUrl}', encodeURIComponent(pageUrl))
			.replace('{webSiteId}', webSiteId)
			.replace('{locale}', locale);
	}

	public getPagePathForSection(sectionId: string) {
		const startAt: number = sectionId.indexOf('__sections');
		if (startAt >= 0) {
			sectionId = sectionId.substring(startAt);
		}
		return '__sections/' + sectionId;
	}

	public getPagePath(contentUrl: string) {
		const sectionId: string = tryExtractSectionId(contentUrl);
		if (sectionId) {
			return this.getPagePathForSection(sectionId);
		}
		const customization = this.customizationService.getCustomization();
		if (customization && customization.canGetPath()) {
			return customization.getPath(contentUrl);
		}
		return getPathSimple(contentUrl);
	}
}
